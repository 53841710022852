import { Avatar, Badge, Card, Dropdown, Space } from "antd";
import {
    WifiOutlined,
    DisconnectOutlined,
    MoreOutlined
  } from '@ant-design/icons';

type Props = {
    label: string;
    body: any;
    indicators: {
        online?: number;
        offline?: number;
    };
    noGutter?: boolean;
};

const BaseCard = (props: Props) => {
    return (
        <Card
            title={props.label}
            extra={
                <div>
                    <Space size="middle">
                        <Badge count={props.indicators.online??0} style={{ backgroundColor: '#52c41a', }}>
                            <Avatar icon={<WifiOutlined />} shape="square" size="small" />
                        </Badge>
                        <Badge count={props.indicators.offline??0} showZero={false}>
                            <Avatar icon={<DisconnectOutlined />} shape="square" size="small" />
                        </Badge>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        label: 'Add Device',
                                        key: 'add',
                                        onClick: () => alert('Add ?')
                                    },
                                    {
                                        label: `Edit ${props.label}`,
                                        key: 'edit',
                                        onClick: () => alert('Edit ?')
                                    },
                                    {
                                        label: `Delete ${props.label}`,
                                        key: 'delete',
                                        onClick: () => alert('Delete ?')
                                    }
                                ]
                            }}
                            trigger={['click']}
                        >
                            <span style={{cursor:'pointer'}}>
                                <strong><MoreOutlined /></strong>
                            </span>
                        </Dropdown>
                    </Space>
                </div>
            }
            size="default"
            style={{ width: '100%', marginBottom: !props.noGutter?'20px':'0px' }}
            bordered={false}
        >
            {props.body}
        </Card>
    );
}
export default BaseCard;