import React, { useEffect, useState } from 'react';
import {
  DesktopOutlined,
  PieChartOutlined,
  WifiOutlined,
  AppstoreAddOutlined,
  ApartmentOutlined,
  DisconnectOutlined,
  HomeOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import { Col, MenuProps, Row, Skeleton, Space, Tabs, Tag, Card, Switch } from 'antd';
import { Layout, Menu, theme } from 'antd';

import logo from './images/smarden_logo.png';





import BaseCard from './components/molecules/BaseCard';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Dashboard', '1', <PieChartOutlined />),
  getItem('Templates', '2', <DesktopOutlined />),
  /*
  getItem('User', 'sub1', <UserOutlined />, [
    getItem('Tom', '3'),
    getItem('Bill', '4'),
    getItem('Alex', '5'),
  ]),
  getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
  getItem('Files', '9', <FileOutlined />),
  */
];

const App: React.FC = () => {

  const { sendMessage, lastMessage, readyState } = useWebSocket('ws://broker.smarden.tech?clientId=adii');
  const [switchData, setSwitchData] = useState<{[key: string]: boolean}>({});
  const [refresh, setRefresh] = useState<1 | 2>(1);

  useEffect(() => {
    if(lastMessage) {
      try {
        let msg: any = JSON.parse(lastMessage.data);
        console.log(lastMessage);
        if(msg.data) {
          let currData: any = {...switchData};
          msg.data.forEach((s: any) => {
            currData['smarden-device-1___'+s.switch] = s.state;
          });
          setSwitchData(currData);
          setRefresh(refresh===1?2:1);
          //console.log(currData);
        }
      }
      catch(e) {}
    }
  }, [lastMessage]);

  const updateSwitch = (s: {switch: 1 | 2 | 3, state: boolean}) => {
    sendMessage(JSON.stringify({
      scope: 'device',
      params: {
        deviceId: 'smarden-device-1'
      },
      data: [s]
    }));
  }


  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [spaces, setSpaces] = useState<any[]>([]);

  useEffect(() => {
    setSpaces([
      {
        label: 'Bed Room',
        indicators: {online: 4, offline: 0}
      },
      /*
      {
        label: 'Drawing Room',
        indicators: {online: 4, offline: 0}
      },
      {
        label: 'Washroom',
        indicators: {online: 0, offline: 5}
      },
      {
        label: 'Kitchen',
        indicators: {online: 3, offline: 0}
      },
      {
        label: 'Terrace',
        indicators: {online: 6, offline: 1}
      },
      {
        label: 'Hall',
        indicators: {online: 2, offline: 3}
      },
      {
        label: 'Balcony',
        indicators: {online: 3, offline: 1}
      },
      */
    ]);
  }, []);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme='light'>
        <div style={{ height:80, padding:!collapsed?'15px 20px 5px 20px':'20px 5px 10px 5px', margin: 0, textAlign:'center' }}>
          <img src={logo} style={{width:!collapsed?'70%':'90%'}} />
        </div>
        <Menu theme="light" defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, }} />
        <Content style={{ margin: '0 16px' }}>
          <div style={{marginTop:'20px' }}>
            <Tabs
              defaultActiveKey="1"
              type="card"
              size={'middle'}
              items={new Array(3).fill(null).map((_, i) => {
                const id: string = String(i + 1);
                return {
                  label: <div><HomeOutlined />Home {id}</div>,
                  key: id,
                  children: [
                    (
                      <div key={(new Date().getTime())+''+id} style={{
                        marginTop:-17,
                        height: 'calc(100vh - 190px)',
                        background: colorBgContainer,
                        borderLeft: '1px solid rgba(5, 5, 5, 0.06)',
                        overflow: 'auto',
                        borderBottomLeftRadius:'5px',
                        borderBottomRightRadius:'5px',
                      }}>
                        <Space size={'middle'} direction="vertical" style={{ display: 'flex' }}>
                          <div style={{overflow: 'auto', padding:'10px'}}>
                            <Space size={[10, 20]}>
                              <Tag icon={<ApartmentOutlined />} color="default">Total Rooms: <strong>4</strong></Tag>
                              <Tag icon={<AppstoreAddOutlined />} color="default">Total Devices: <strong>42</strong></Tag>
                              <Tag icon={<WifiOutlined />} color="success">Online Devices: <strong>30</strong></Tag>
                              <Tag icon={<DisconnectOutlined />} color="error">Offline Devices: <strong>12</strong></Tag>
                            </Space>
                          </div>
                          <div style={{overflow: 'auto', height: 'calc(100vh - 233px)', marginTop:'-16px'}}>
                            <Row gutter={20} style={{background:'rgb(240, 242, 245)', padding:'20px 20px 0px 20px', height:'100%'}}>
                              {spaces.map((space: any, i: number) => {
                                let {label, indicators} = space;
                                return (
                                  <Col xs={24} sm={24} lg={6} key={'space_'+i} style={{height:'0px'}}>
                                    <BaseCard
                                      label={label}
                                      body={(
                                        (refresh && (
                                          <Skeleton loading={false} avatar>
                                            <Space>
                                              <Card>
                                                <label>Switch 1</label>
                                                <div style={{transform: 'rotate(90deg)', marginTop:'20px'}}>
                                                  <Switch
                                                    checkedChildren={(
                                                      <span style={{transform: 'rotate(270deg)', display:'inline-block'}}>
                                                        <CheckOutlined />
                                                      </span>
                                                    )}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    defaultChecked={switchData['smarden-device-1___1']}
                                                    onChange={(state: any) => updateSwitch({switch: 1, state})}
                                                  />
                                                </div>
                                              </Card>
                                              <Card>
                                                <label>Switch 2</label>
                                                <div style={{transform: 'rotate(90deg)', marginTop:'20px'}}>
                                                  <Switch
                                                    checkedChildren={(
                                                      <span style={{transform: 'rotate(270deg)', display:'inline-block'}}>
                                                        <CheckOutlined />
                                                      </span>
                                                    )}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    defaultChecked={switchData['smarden-device-1___2']}
                                                    onChange={(state: any) => updateSwitch({switch: 2, state})}
                                                  />
                                                </div>
                                              </Card>
                                              <Card>
                                                <label>Switch 3</label>
                                                <div style={{transform: 'rotate(90deg)', marginTop:'20px'}}>
                                                  <Switch
                                                    checkedChildren={(
                                                      <span style={{transform: 'rotate(270deg)', display:'inline-block'}}>
                                                        <CheckOutlined />
                                                      </span>
                                                    )}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    defaultChecked={switchData['smarden-device-1___3']}
                                                    onChange={(state: any) => updateSwitch({switch: 3, state})}
                                                  />
                                                </div>
                                              </Card>
                                              {/*
                                              <Button style={{padding:'0px', width:'50px', height:'50px'}}>
                                                <img src={logoDevESP} style={{width:'100%', height:'100%'}} />
                                              </Button>
                                              <Button style={{padding:'0px', width:'50px', height:'50px'}}>
                                                <img src={logoDevNMCU} style={{width:'100%', height:'100%'}} />
                                              </Button>
                                              <Button style={{padding:'0px', width:'50px', height:'50px'}}>
                                                <img src={logoDevRPI} style={{width:'100%', height:'100%'}} />
                                              </Button>
                                              */}
                                            </Space>
                                          </Skeleton>
                                        ))
                                      )}
                                      indicators={indicators}
                                    />
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        </Space>
                      </div>
                    ),
                    (
                      <div key={id}>
                        123
                      </div>
                    )
                  ]
                };
              })}
            />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>SmarDen &copy; 2023</Footer>
      </Layout>
    </Layout>
  );
};

export default App;